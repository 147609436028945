import { render, staticRenderFns } from "./ShortInfoProductCompare.vue?vue&type=template&id=63d055c8&"
import script from "./ShortInfoProductCompare.vue?vue&type=script&lang=js&"
export * from "./ShortInfoProductCompare.vue?vue&type=script&lang=js&"
import style0 from "./ShortInfoProductCompare.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports