import { baseDomain } from '../../constans';

const projects = {
  namespaced: true,
  state: {
    margin: false,
    project: {},
    complectations: [],
    staticProject: {
      titlesTable(currency, affilateState) {
        if (affilateState === true) {
          return {
            ru: ['№', 'Наименование', 'Программа', `Цена, ${currency}`, 'Цена в комплекте', 'Количество', `Сумма, ${currency}`, 'Сумма в комплекте', `Опт, ${currency}`, `Маржа, ${currency}`],
            ua: ['№', 'Найменування', 'Програма', `Ціна, ${currency}`, 'Ціна в комплекті', 'Кількість', `Сума, ${currency}`, 'Сума в комплекті', `Опт, ${currency}`, `Маржа, ${currency}`],
            en: ['#', 'Name', 'Program', `Price, ${currency}`, 'Price in set', 'Quantity', `Sum, ${currency}`, 'Sum in set', `Wholesale, ${currency}`, `Margin, ${currency}`],
          };
        } else {
          return {
            ru: ['№', 'Наименование', 'Программа', `Цена, ${currency}`, 'Цена в комплекте', 'Количество', 'Скидка,%', `Сумма, ${currency}`, 'Сумма в комплекте', `Закупка, ${currency}`, 'Маржа,%', 'Комплектация', ''],
            ua: ['№', 'Найменування', 'Програма', `Ціна, ${currency}`, 'Ціна в комплекті', 'Кількість', 'Знижка,%', `Сума, ${currency}`, 'Сума в комплекті', `Закупівля, ${currency}`, 'Маржа,%', 'Комплектація', ''],
            en: ['#', 'Name', 'Program', `Price, ${currency}`, 'Price in set', 'Quantity', 'Discount,%', `Sum, ${currency}`, 'Sum in set', `Purchase, ${currency}`, 'Margin,%', 'Equipment', ''],
          };
        }
      },
      messageProjectOrderDate(date14, date) {
        return {
          ru: `Бесплатное хранение до ${date14} года (${date} + 14 дней)`,
          ua: `Безкоштовне зберігання до ${date14} року (${date} + 14 днів)`,
          en: `Free storage up to ${date14} year (${date} + 14 days)`,
        };
      },
      descProjectOrderDate(currency) {
        return {
          ru: `Платное хранение за единицу 12 ${currency} в сутки`,
          ua: `Платне зберігання за одиницю 12 ${currency} на добу`,
          en: `Paid storage per unit 12 ${currency} per day`,
        };
      },
    },
    delivery: [],
    orders: {
      orders: [],
      countPage: null,
    },
    projects: [],
    popups: {
      statusPopup: false,
      text: '',
    },
    countPage: 1,
    isLoading: false,
  },
  mutations: {
    SET_PROJECT(state, data) {
      data.products.forEach((e) => {
        e.active = true;
      });
      state.project = data;
    },
    SET_PROJECTS(state, data) {
      data.projects.forEach((e) => {
        e.status.isShowed = false;
      });

      state.projects = data.projects;
      state.countPage = data.countPage;
    },
    SET_ORDERS(state, data) {
      state.orders.countPage = data.count_page;
      state.orders.orders = data.orders.map((order) => {
        order.isNotificationEnabled = false;
        order.exclamationMarkFill = '#000';
        const currentDate = new Date();
        const DAYS_14_IN_MS = 14 * 24 * 60 * 60 * 1000;
        const orderDate = new Date(order.date); // Если нет даты то будет "Invalid Date"

        if (currentDate.getTime() >= orderDate.getTime()) {
          // "Invalid Date".getTime() даст нам NaN -> любое сравнение с NaN даст false
          order.isNotificationEnabled = true;
          order.exclamationMarkFill = '#129227';
          if (currentDate.getTime() >= orderDate.getTime() + DAYS_14_IN_MS) {
            order.exclamationMarkFill = '#f00';
          }
        }

        return order;
      });
    },
    CLEAN_ORDERS(state) {
      state.orders.orders = [];
      state.orders.countPage = null;
    },
    SET_COMPLECTATIONS(state, data) {
      state.complectations = data;
    },
    SET_DELIVERY(state, data) {
      state.delivery = data;
    },
    SET_MARGIN(state, flag) {
      state.margin = flag;
    },
    DELETE_PROJECT(state, id) {
      state.projects.forEach((project, index, projects) => {
        if (project.id === +id) {
          projects.splice(index, 1);
        }
      });
    },
    DELETE_PRODUCT(state, id) {
      state.project.products.forEach((product, index, products) => {
        if (product.id === +id) {
          products.splice(index, 1);
        }
      });
    },
    SET_POPUP(state, data) {
      if (data && data.message) {
        state.popups.text = data.message;
      }

      if (data && data.success) {
        state.popups.statusPopup = true;
      } else {
        state.popups.statusPopup = false;
      }
    },
    ACTIVE_LOADER(state) {
      state.isLoading = !state.isLoading;
    },
  },
  actions: {
    async getComplectations({ commit, dispatch }) {
      const { token } = JSON.parse(localStorage.alcotecUserInfo).user;

      fetch(`${baseDomain}/complectations`, {
        headers: {
          'auth-token': token,
        },
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.renew_jwt) {
            dispatch('profiles/refreshToken', { action: 'projects/getComplectations' }, { root: true });
          } else {
            commit('SET_COMPLECTATIONS', response.data);
          }
        })
        .catch((err) => console.error(err));
    },
    async getDelivery({ commit, dispatch }) {
      const { token } = JSON.parse(localStorage.alcotecUserInfo).user;

      fetch(`${baseDomain}/delivery`, {
        headers: {
          'auth-token': token,
        },
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.renew_jwt) {
            dispatch('profiles/refreshToken', { action: 'projects/getDelivery' }, { root: true });
          } else {
            commit('SET_DELIVERY', response.data);
          }
        })
        .catch((err) => console.error(err));
    },
    async getProject({ commit, dispatch }, { id, cb }) {
      const { token } = JSON.parse(localStorage.alcotecUserInfo).user;

      commit('ACTIVE_LOADER');
      fetch(`${baseDomain}/project/${id}`, {
        headers: {
          'auth-token': token,
        },
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.renew_jwt) {
            dispatch('profiles/refreshToken', { action: 'projects/getProject', id, cb }, { root: true });
          } else if (response.data) {
            commit('SET_PROJECT', response.data);
            commit('ACTIVE_LOADER');
            cb(true);
          } else {
            commit('ACTIVE_LOADER');
            cb(false);
          }
        })
        .catch((err) => console.error(err));
    },
    async getProjectsTable({ commit, dispatch }, {
      cb,
      page = 1,
      PER_PAGE = 7,
      sortData = false,
      searchValue = '',
      showPromo = '',
    }) {
      const { token } = JSON.parse(localStorage.alcotecUserInfo).user;

      fetch(`${baseDomain}/projects?page=${page}&per_page=${PER_PAGE}&sort_date=${sortData}&search_value=${searchValue}${(showPromo ? `&show_promo=${showPromo}` : '')}`, {
        headers: {
          'auth-token': token,
        },
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.renew_jwt) {
            dispatch('profiles/refreshToken', {
              action: 'projects/getProjectsTable', cb, page, sortData, PER_PAGE, searchValue, showPromo,
            }, { root: true });
          } else {
            commit('SET_PROJECTS', response.data);
            cb();
          }
        })
        .catch((err) => console.error(err));
    },
    async getOrders({ commit, dispatch }, {
      cb,
      page = 1,
      PER_PAGE = 7,
      sortDate = false,
      searchValue = '',
      showPromo = '',
    }) {
      const { token } = JSON.parse(localStorage.alcotecUserInfo).user;

      fetch(`${baseDomain}/orders?page=${page}&per_page=${PER_PAGE}&sort_zid=${sortDate}&search_value=${searchValue}${(showPromo ? `&show_promo=${showPromo}` : '')}`, {
        headers: {
          'auth-token': token,
        },
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.renew_jwt) {
            dispatch('profiles/refreshToken', {
              action: 'projects/getOrders', cb, page, PER_PAGE, sortDate, searchValue, showPromo,
            }, { root: true });
          } else if (response.data) {
            commit('SET_ORDERS', response.data);
            cb();
          } else {
            commit('CLEAN_ORDERS');
            cb();
          }
        })
        .catch((err) => console.error(err));
    },
    async sendingDataProject({ commit, dispatch }, { project, cb, token, url }) {
      fetch(`${baseDomain}/${url}`, {
        headers: {
          'auth-token': token,
        },
        method: 'POST',
        body: JSON.stringify(project),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.renew_jwt) {
            dispatch('profiles/refreshToken', { action: 'projects/sendingDataProject', project, cb, url }, { root: true });
          } else {
            commit('SET_POPUP', response);
            cb(response);
          }
        })
        .catch((err) => console.error(err));
    },
    async deleteProject({ commit, dispatch }, { id, cb }) {
      const { token } = JSON.parse(localStorage.alcotecUserInfo).user;

      fetch(`${baseDomain}/project`, {
        method: 'POST',
        headers: {
          'auth-token': token,
          'x-rest-method': 'DELETE',
        },
        body: JSON.stringify({ id }),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response) {
            if (response.renew_jwt) {
              dispatch('profiles/refreshToken', { action: 'projects/deleteProject', id, cb }, { root: true });
            } else if (response.success) {
              commit('DELETE_PROJECT', id);
              cb();
            }
          } else {
            throw response;
          }
        })
        .catch((err) => console.error(err));
    },
    async searchObjectNP({ commit, dispatch }, { ref, str, type, cb }) {
      const { token } = JSON.parse(localStorage.alcotecUserInfo).user;

      fetch(`${baseDomain}/delivery`, {
        method: 'POST',
        headers: {
          'auth-token': token,
        },
        body: JSON.stringify({
          ref: ref,
          name: str,
          type: type,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response) {
            if (response.renew_jwt) {
              dispatch('profiles/refreshToken', { action: '/delivery', ref, str, type, cb }, { root: true });
            } else if (response.success && response.success === true) {
              cb(response.items);
            }
          } else {
            throw response;
          }
        })
        .catch((err) => console.error(err));
    },
  },
};

export default projects;
