import axios from 'axios';
import { baseDomain } from '../../constans';
import { fetch as fetchPolyfill } from 'whatwg-fetch';

const users = {
  namespaced: true,
  state: { // состояние приложения, данные, которые внутри приложения используются
    userData: {
      id: 0,
      isLoggedIn: false,
      userName: '',
      role: '',
      type: '',
      typeKA: '',
      manager: '',
      affilate: '',
      optPriceVisibilityInItems: false,
      optPriceDownloadLink: false,
    },
    checkout: {
      items: [],
      sumPrice: 0,
    },
    guestPopupStatus: false,
    statusJwtPopup: false,
  },
  getters: { // нужны для того чтобы вынимать какие то данные из общих данных приложения, из state, например если будет сложный объект с данными, чтобы не вытягивать каждый раз весь объект, мы можем здесь создать метод, который будет вытаскивать нам только нужные данные
    isLoggedIn: (state) => state.userData.isLoggedIn,
  },
  mutations: { // нужны для того, чтобы описывать методы, которые будут изменять данные нашего приложения
    SET_LOGIN_USER(state, data) {
      if (data && data.user && data.user.id) {
        state.userData.id = data.user.id;
        state.userData.userName = data.user.name;
        state.userData.isLoggedIn = true;
        state.userData.manager = data.user.manager;
        state.userData.affilate = data.user.affilate;
        state.userData.role = data.user.role;
        state.userData.type = data.user.type;
        state.userData.typeKA = data.user.typeKA;
        state.userData.optPriceVisibilityInItems = data.user.optPriceVisibilityInItems;
        state.userData.optPriceDownloadLink = data.user.optPriceDownloadLink;
      }
    },
    SET_LOGOUT_USER(state) {
      state.userData.id = 0;
      state.userData.userName = '';
      state.userData.role = '';
      state.userData.manager = '';
      state.userData.affilate = '';
      state.userData.isLoggedIn = false;
      state.userData.type = '';
      state.userData.typeKA = '';
      state.userData.optPriceVisibilityInItems = false;
      state.userData.optPriceDownloadLink = false;
    },
    CHANGE_GUEST_POPUP(state) {
      state.guestPopupStatus = !state.guestPopupStatus;
    },
    CHANGE_JWT_POPUP(state) {
      state.statusJwtPopup = !state.statusJwtPopup;
    },
  },
  actions: {
    async regUser({ commit }, { data, cb }) {
      const gid = window.$cookies.get('_ga');

      fetch(`${baseDomain}/signup`, {
        method: 'POST',
        body: JSON.stringify({
          name: data.name,
          city: data.city,
          phone: data.phone,
          email: data.email,
          group: data.group,
          gid: gid,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          cb(response);
        })
        .catch((err) => console.error(err));
    },

    async loginUser({ commit }, { data, cb }) {
      fetch(`${baseDomain}/login`, {
        method: 'POST',
        body: JSON.stringify({ login: data.email, password: data.password }),
      })
        .then((response) => response.json())
        .then((response) => {
          cb(response);
        })
        .catch((err) => console.error(err));
    },

    async recoveryPassword({ commit }, { email, cb }) {
      fetch(`${baseDomain}/recovery-password`, {
        method: 'POST',
        body: JSON.stringify({ email: email }),
      })
        .then((response) => response.json())
        .then((response) => {
          cb(response);
        })
        .catch((e) => console.error(e));
    },

    async logoutUser({ commit, dispatch }, { cb }) {
      const { token } = JSON.parse(localStorage.alcotecUserInfo).user;

      fetchPolyfill(`${baseDomain}/logout`, {
        method: 'POST',
        headers: {
          'auth-token': token,
        },
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.renew_jwt) {
            dispatch('profiles/refreshToken', { action: 'users/logoutUser', cb }, { root: true });
          } else if (response.success) {
            commit('SET_LOGOUT_USER');
            cb();
          } else {
            throw response;
          }
        })
        .catch((e) => console.error(e));
    },

    async review({ commit }, { data, cb }) {
      const token = localStorage.alcotecUserInfo
        && JSON.parse(localStorage.alcotecUserInfo).user
        && JSON.parse(localStorage.alcotecUserInfo).user.token || '';

      const gid = window.$cookies.get('_ga');

      fetch(`${baseDomain}/review`, {
        method: 'POST',
        headers: {
          'auth-token': token,
        },
        body: JSON.stringify({
          type: data.type,
          text: data.text,
          url: data.url,
          gid: gid,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          cb(response);
        })
        .catch((err) => console.error(err));
    },

    async fastBuy({ commit }, { data, cb }) {
      fetch(`${baseDomain}/fast-buy`, {
        method: 'POST',
        body: JSON.stringify({ product: data.product, phone: data.phone, url: data.url }),
      })
        .then((response) => response.json())
        .then((response) => {
          cb(response);
        })
        .catch((err) => console.error(err));
    },
    async guestBuy({ commit }, { data, cb }) {
      const gid = window.$cookies.get('_ga');

      fetch(`${baseDomain}/guest-basket`, {
        method: 'POST',
        body: JSON.stringify({
          name: data.name,
          phone: data.phone,
          email: data.email,
          address: data.address,
          comment: data.text,
          products: data.products,
          promocode: data.promocode,
          gid: gid,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          cb(response);
        })
        .catch((err) => console.error(err));
    },
    async getTrainingThemes({ commit, dispatch }, { cb, error }) {
      const token = localStorage.alcotecUserInfo
        && JSON.parse(localStorage.alcotecUserInfo).user
        && JSON.parse(localStorage.alcotecUserInfo).user.token || '';

      fetch(`${baseDomain}/training-themes`, {
        headers: {
          'auth-token': token,
        },
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.renew_jwt) {
            dispatch('profiles/refreshToken', { action: 'users/getTrainingThemes', cb, error }, { root: true });
          } else if (response.success === false) {
            error();
          } else if (response.success === true) {
            cb(response);
          }
        })
        .catch((err) => console.error(err));
    },
    async trainingRegistration({ commit, dispatch }, { data, cb }) {
      const token = localStorage.alcotecUserInfo
        && JSON.parse(localStorage.alcotecUserInfo).user
        && JSON.parse(localStorage.alcotecUserInfo).user.token || '';

      fetch(`${baseDomain}/training-registration`, {
        method: 'POST',
        headers: {
          'auth-token': token,
        },
        body: JSON.stringify({
          theme: data.theme,
          name: data.name,
          phone: data.phone,
          email: data.email,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.renew_jwt) {
            dispatch('profiles/refreshToken', { action: 'users/trainingRegistration', cb }, { root: true });
          } else {
            cb(response);
          }
        })
        .catch((err) => console.error(err));
    },
    async getBalance({ commit, dispatch }, { cb }) {
      const token = localStorage.alcotecUserInfo
        && JSON.parse(localStorage.alcotecUserInfo).user
        && JSON.parse(localStorage.alcotecUserInfo).user.token || '';

      fetch(`${baseDomain}/balance`, {
        headers: {
          'auth-token': token,
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');

          a.href = url;
          a.download = 'leftovers.xls';
          document.body.appendChild(a);
          a.click();
          a.remove();
          cb();
        })
        .catch((err) => console.error(err));
    },
    async getRefreshBalance({ dispatch }, { cb }) {
      dispatch('profiles/refreshToken', { action: 'users/getBalance', cb }, { root: true });
    },
  },
};

export default users;
